import { ChainId, Token } from '@javaswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  java: new Token(
    MAINNET,
    '0x4aFaE971Ac146d4028c3Ed581Eb307A1615E59Fe',
    18,
    'JAVA',
    'JavaSwap Token',
    'https://javaswap.io/',
  ),
  wmatic: new Token(
    MAINNET,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WMATIC',
    'Wrapped MATIC',
    'https://polygon.technology/',
  ),
  // matic here points to the wmatic contract. Wherever the currency MATIC is required, conditional checks for the symbol 'MATIC' can be used
  matic: new Token(
    MAINNET,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'MATIC',
    'MATIC',
    'https://polygon.technology/',
  ),
  javav1: new Token(
    MAINNET,
    '0xAFC9AA5ebd7197662D869F75890F18AafEEFb1f5',
    18,
    'JAVA',
    'JavaSwap Token',
    'https://javaswap.io/',
  ),
  javav2: new Token(
    MAINNET,
    '0xeB5B7b7f03200bd25aBf9bD68A57F945Ff5b1428',
    18,
    'JAVA',
    'JavaSwap Token',
    'https://javaswap.io/',
  ),
  usdc: new Token(
    MAINNET,
    '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    6,
    'USDC',
    'USDC Coin',
    'https://www.circle.com/en/usdc',
  ),
  weth: new Token(
    MAINNET,
    '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io/',
  ),
  wbtc: new Token(
    MAINNET,
    '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    8,
    'WBTC',
    'Wrapped BTC',
    'https://wbtc.network/',
  ),
  dlycop: new Token(
    MAINNET,
    '0x1659fFb2d40DfB1671Ac226A0D9Dcc95A774521A',
    18,
    'DLYCOP',
    'Daily COP',
    'https://dlycrypto.com/',
  ),
  usdt: new Token(
    MAINNET,
    '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    6,
    'USDT',
    'Tether USD',
    'https://mapper.matic.today/',
  ),
  uni: new Token(
    MAINNET,
    '0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
    18,
    'UNI',
    'Uniswap',
    'https://uniswap.org/',
  ),
  quick: new Token(
    MAINNET,
    '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    18,
    'QUICK',
    'Quickswap',
    'https://quickswap.exchange/',
  ),
  sushi: new Token(
    MAINNET,
    '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
    18,
    'SUSHI',
    'SushiToken',
    'https://sushi.com/',
  ),
  ftm: new Token(
    MAINNET,
    '0xB85517b87BF64942adf3A0B9E4c71E4Bc5Caa4e5',
    18,
    'FTM',
    'Fantom Token',
    'https://www.fantom.foundation/',
  ),
  shib: new Token(
    MAINNET,
    '0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec',
    18,
    'SHIB',
    'SHIBA INU',
    'https://mapper.matic.today/',
  ),
}

export const testnetTokens = {
  wmatic: new Token(
    TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'WMATIC',
    'Wrapped MATIC',
    'https://polygon.technology/',
  ),
  java: new Token(
    TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'JAVA',
    'JavaSwap Token',
    'https://javaswap.io/',
  ),
  usdc: new Token(
    TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    6,
    'USDC',
    'Binance USD',
    'https://www.paxos.com/usdc/',
  ),
  espresso: new Token(
    TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'ESPRESSO',
    'Espresso Token',
    'https://javaswap.io/',
  ),
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
