import { ChainId } from '@javaswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMasterBrewAddress = () => {
  return getAddress(addresses.masterBrew)
}
export const getMasterBrewV1Address = () => {
  return getAddress(addresses.masterBrewV1)
}
export const getMasterBrewV2Address = () => {
  return getAddress(addresses.masterBrewV2)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getJavaProfileAddress = () => {
  return getAddress(addresses.javaProfile)
}
export const getJavaRabbitsAddress = () => {
  return getAddress(addresses.javaRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getJavaVaultAddress = () => {
  return getAddress(addresses.javaVault)
}
export const getJavaVaultV1Address = () => {
  return getAddress(addresses.javaVaultV1)
}
export const getJavaVaultV2Address = () => {
  return getAddress(addresses.javaVaultV2)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialJavaVaultAddress = () => {
  return getAddress(addresses.bunnySpecialJavaVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getReferralContractAddress = () => {
  return getAddress(addresses.referral)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
