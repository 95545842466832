import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL } from 'config'
import getGasPrice from 'utils/getGasPrice'
import { splitSignature } from '@ethersproject/bytes'
import { ROUTER_ADDRESS } from 'config/constants'
import { ChainId, Pair, TokenAmount, JSBI, Token } from '@javaswap/sdk'
import javaABI from 'config/abi/java.json'
import javaPairABI from 'config/abi/javaPair.json'
import masterBrewABI from 'config/abi/masterbrew.json'
import multicall from 'utils/multicall'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { getContract, getRouterContract } from 'utils'
import { mainnetTokens } from 'config/constants/tokens'
import { ethers } from 'ethers'
import { unstakeFarm } from './farms'
import { withdrawAllVault } from './pools'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

let signatureData = null

export const migrateStep1 = async (masterBrewContract, farms, account) => {
  let error = false

  const calls = farms.map((farm) => ({
    address: masterBrewContract.address,
    name: 'userInfo',
    params: [farm.pid, account],
  }))

  const rawResults = await multicall(masterBrewABI, calls)

  const results = farms.map((farm, index) => ({ ...farm, balance: new BigNumber(rawResults[index].amount._hex) }))
  // eslint-disable-next-line no-restricted-syntax
  for (const farm of results) {
    try {
      if (farm.balance.isGreaterThan(0)) {
        // eslint-disable-next-line no-await-in-loop
        await unstakeFarm(masterBrewContract, farm.pid, farm.balance.div(DEFAULT_TOKEN_DECIMAL))
      }
    } catch (e) {
      error = true
    }
  }
  return error
}

export const migrateStep2 = async (javaVaultContract) => {
  let error = false
  try {
    await withdrawAllVault(javaVaultContract)
  } catch (e) {
    error = true
  }

  return error
}

export const migrateStep3 = async (account, deadline, library, farms, javaAddress) => {
  const calls = farms.map((farm) => ({
    address: farm.address,
    name: 'balanceOf',
    params: [account],
  }))

  const rawResults = await multicall(javaABI, calls)
  const results = farms.map((farm, index) => ({ ...farm, balance: new BigNumber(rawResults[index]) }))

  const farmsWithBalances = results.filter((balanceType) => balanceType.balance.gt(0))

  let error = false
  try {
    for (let i = 0; i < farmsWithBalances.length; i++) {
      if (
        farmsWithBalances[i].token.address !== javaAddress &&
        farmsWithBalances[i].quoteToken.address !== javaAddress
      ) {
        // eslint-disable-next-line
        continue
      }
      // eslint-disable-next-line no-await-in-loop
      const pairContract = await getContract(farmsWithBalances[i].address, IUniswapV2PairABI, library)
      // eslint-disable-next-line no-await-in-loop
      await attemptToApprove(
        pairContract,
        farmsWithBalances[i].address,
        library,
        deadline,
        farmsWithBalances[i].balance,
        account,
        ChainId.MAINNET,
      )
      // eslint-disable-next-line no-await-in-loop
      await remove(
        ChainId.MAINNET,
        library,
        account,
        farmsWithBalances[i].balance,
        farmsWithBalances[i].address,
        farmsWithBalances[i].token,
        farmsWithBalances[i].quoteToken,
      )
    }
  } catch (e) {
    error = true
  }

  return error
}

export const migrateStep4 = async (account, JavaContract, JavaV2Contract, from) => {
  let error = false

  try {
    const calls = [
      {
        address: JavaContract.address,
        name: 'balanceOf',
        params: [account],
      },
      {
        address: JavaContract.address,
        name: 'allowance',
        params: [account, JavaV2Contract.address],
      },
    ]

    const rawResults = await multicall(javaABI, calls)
    const balanceOfJava = rawResults[0]
    const allowanceJava = rawResults[1]
    const gasPrice = await getGasPrice()

    if (balanceOfJava.toString() === '0') {
      return error
    }

    if (allowanceJava < balanceOfJava) {
      try {
        const txJava = await JavaContract.approve(mainnetTokens.java.address, ethers.constants.MaxUint256, {
          ...options,
          gasPrice,
        })
        await txJava.wait()
      } catch (e) {
        error = false
      }
    }

    let txJavaV2 = null
    if (from === 'v1') {
      txJavaV2 = await JavaV2Contract.swapFromV1(balanceOfJava.toString(), { ...options, gasPrice })
    } else {
      txJavaV2 = await JavaV2Contract.swapFromV2(balanceOfJava.toString(), { ...options, gasPrice })
    }

    await txJavaV2.wait()
  } catch (e) {
    error = true
  }

  return error
}

async function attemptToApprove(pairContract, liquidityAddress, library, deadline, liquidity, account, chainId) {
  if (!pairContract || !liquidityAddress || !library || !deadline) throw new Error('missing dependencies')
  const liquidityAmount = liquidity
  if (!liquidityAmount) throw new Error('missing liquidity amount')

  // try to gather a signature for permission
  const nonce = await pairContract.nonces(account)

  const EIP712Domain = [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'chainId', type: 'uint256' },
    { name: 'verifyingContract', type: 'address' },
  ]
  const domain = {
    name: 'Java LPs',
    version: '1',
    chainId,
    verifyingContract: liquidityAddress,
  }
  const Permit = [
    { name: 'owner', type: 'address' },
    { name: 'spender', type: 'address' },
    { name: 'value', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' },
  ]
  const message = {
    owner: account,
    spender: ROUTER_ADDRESS,
    value: liquidityAmount.toString(),
    nonce: nonce.toHexString(),
    deadline: deadline.toNumber(),
  }

  const data = JSON.stringify({
    types: {
      EIP712Domain,
      Permit,
    },
    domain,
    primaryType: 'Permit',
    message,
  })

  await library
    .send('eth_signTypedData_v4', [account, data])
    .then(splitSignature)
    .then((signature) => {
      signatureData = {
        v: signature.v,
        r: signature.r,
        s: signature.s,
        deadline: deadline.toNumber(),
      }
    })
    .catch((err) => {
      throw err
    })
}

async function remove(chainId, library, account, userLiquidity, lpAddress, token, quoteToken) {
  const router = getRouterContract(chainId, library, account)
  const gasPrice = await getGasPrice()
  const calls = [
    {
      address: lpAddress,
      name: 'token0',
    },
    {
      address: lpAddress,
      name: 'token1',
    },
    {
      address: lpAddress,
      name: 'getReserves',
    },
    {
      address: lpAddress,
      name: 'totalSupply',
    },
  ]
  const rawResults = await multicall(javaPairABI, calls)
  const tokenA =
    rawResults[0][0] === token.address
      ? new Token(chainId, token.address, token.decimals, token.symbol, token.name, token.projectLink)
      : new Token(
          chainId,
          quoteToken.address,
          quoteToken.decimals,
          quoteToken.symbol,
          quoteToken.name,
          quoteToken.projectLink,
        )
  const tokenB =
    rawResults[1][0] === token.address
      ? new Token(chainId, token.address, token.decimals, token.symbol, token.name, token.projectLink)
      : new Token(
          chainId,
          quoteToken.address,
          quoteToken.decimals,
          quoteToken.symbol,
          quoteToken.name,
          quoteToken.projectLink,
        )

  const pair = new Pair(
    new TokenAmount(tokenA, rawResults[2]._reserve0.toString()),
    new TokenAmount(tokenB, rawResults[2]._reserve1.toString()),
  )

  const totalSupply = rawResults[3]

  const JSBIuserLiquidity = new TokenAmount(pair.liquidityToken, userLiquidity.toString())
  const JSBItotalSupply = new TokenAmount(pair.liquidityToken, totalSupply.toString())

  const liquidityValueA = JSBI.greaterThanOrEqual(JSBItotalSupply.raw, JSBIuserLiquidity.raw)
    ? new TokenAmount(tokenA, pair.getLiquidityValue(tokenA, JSBItotalSupply, JSBIuserLiquidity, false).raw)
    : undefined
  const liquidityValueB = JSBI.greaterThanOrEqual(JSBItotalSupply.raw, JSBIuserLiquidity.raw)
    ? new TokenAmount(tokenB, pair.getLiquidityValue(tokenB, JSBItotalSupply, JSBIuserLiquidity, false).raw)
    : undefined

  let methodNames: string[]
  let args: Array<string | string[] | number | boolean>

  const currencyBIsETH = tokenB.address === mainnetTokens.wmatic.address
  const oneCurrencyIsETH = tokenA.address === mainnetTokens.wmatic.address || currencyBIsETH

  const amountMinA = JSBI.divide(liquidityValueA.raw, JSBI.BigInt(10)).toString()
  const amountMinB = JSBI.divide(liquidityValueB.raw, JSBI.BigInt(10)).toString()

  if (oneCurrencyIsETH) {
    methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens']
    args = [
      currencyBIsETH ? tokenA.address : tokenB.address,
      userLiquidity.toString(),
      currencyBIsETH ? amountMinA.toString() : amountMinB.toString(),
      currencyBIsETH ? amountMinB.toString() : amountMinA.toString(),
      account,
      signatureData.deadline,
      false,
      signatureData.v,
      signatureData.r,
      signatureData.s,
    ]
  }
  // removeLiquidityETHWithPermit
  else {
    methodNames = ['removeLiquidityWithPermit']
    args = [
      tokenA.address,
      tokenB.address,
      userLiquidity.toString(),
      amountMinA.toString(),
      amountMinB.toString(),
      account,
      signatureData.deadline,
      false,
      signatureData.v,
      signatureData.r,
      signatureData.s,
    ]
  }

  const methodName = methodNames[0]

  await router[methodName](...args, {
    gasLimit: DEFAULT_GAS_LIMIT,
    gasPrice,
  }).catch((err: Error) => {
    // we only care if the error is something _other_ than the user rejected the tx
    throw err
  })
}
