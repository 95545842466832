import { ChainId } from '@javaswap/sdk'
import store from 'state'
import { CONVERT_GWEI } from 'state/user/hooks/helpers'
/**
 * Function to return gasPrice outwith a react component
 */
const getGasPrice = async () => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  const state = store.getState()
  const userGas = state.user.gasName || 'Standard'

  const gasEstimates = await (await fetch('https://gasstation-mainnet.matic.network').then()).json()

  if (userGas === 'Fast') {
    return chainId === ChainId.MAINNET.toString() ? CONVERT_GWEI(gasEstimates.standard) : CONVERT_GWEI('500')
  }
  if (userGas === 'Instant') {
    return chainId === ChainId.MAINNET.toString() ? CONVERT_GWEI(gasEstimates.fast) : CONVERT_GWEI('500')
  }
  return chainId === ChainId.MAINNET.toString() ? CONVERT_GWEI(gasEstimates.safeLow) : CONVERT_GWEI('500')
}

export default getGasPrice
