// eslint-disable
import { useCallback, useEffect, useState } from 'react'

function useApiGasPrice() {
  const [gasPrice, setGasPrice] = useState(null)
  const getGasPrice = useCallback(async () => {
    try {
      const gasEstimates = await (await fetch('https://gasstation-mainnet.matic.network').then()).json()
      setGasPrice(gasEstimates)
    } catch (error) {
      console.error(error)
    }
  }, [])
  useEffect(() => {
    getGasPrice()
  }, [getGasPrice])
  const updateGasPrice = useCallback(() => getGasPrice(), [getGasPrice])
  return [gasPrice, updateGasPrice]
}
export default useApiGasPrice
