import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'JAVA',
    lpAddresses: {
      80001: '',
      137: '0x4aFaE971Ac146d4028c3Ed581Eb307A1615E59Fe',
    },
    token: serializedTokens.java,
    quoteToken: serializedTokens.java,
  },
  {
    pid: 1,
    lpSymbol: 'JAVA-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0x75d4877fe9e2b16b744832c8d91ce15fd0df294e',
    },
    token: serializedTokens.wmatic,
    quoteToken: serializedTokens.java,
  },
  {
    pid: 2,
    lpSymbol: 'USDC-MATIC LP',
    lpAddresses: {
      80001: '',
      137: '0xEf45E5814cC503fD3691DCd9128F4200D4e46D02',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.wmatic,
  },
  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
  {
    pid: 3,
    lpSymbol: 'JAVA-USDC LP',
    lpAddresses: {
      80001: '',
      137: '0xc6f1cdda59a3dc06563376b7953f79d30ea36e7f',
    },
    token: serializedTokens.java,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 4,
    lpSymbol: 'JAVA-WETH LP',
    lpAddresses: {
      80001: '',
      137: '0xDee9B666ADaD1606BDE2e4075CF64018f21eaAb4',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.java,
  },
  {
    pid: 5,
    lpSymbol: 'JAVA-WBTC LP',
    lpAddresses: {
      80001: '',
      137: '0xbe4d6a1F61AB135E5af4c84F4d13C9091b45c7ce',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.java,
  },
  {
    pid: 6,
    lpSymbol: 'JAVA-DLYCOP LP',
    lpAddresses: {
      80001: '',
      137: '0xd3187917aFd8D780F2E3FE1687552C4f4CFEbA7F',
    },
    token: serializedTokens.dlycop,
    quoteToken: serializedTokens.java,
  },
  {
    pid: 7,
    lpSymbol: 'JAVA-USDT LP',
    lpAddresses: {
      80001: '',
      137: '0xa357B4aa07eE6eE71f7DF0F0575e64925A7b34AA',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.java,
  },
  {
    pid: 8,
    lpSymbol: 'JAVA-UNI LP',
    lpAddresses: {
      80001: '',
      137: '0x0D4B195f615982c09EEC2c3165b25d98916b71e7',
    },
    token: serializedTokens.uni,
    quoteToken: serializedTokens.java,
  },
  {
    pid: 9,
    lpSymbol: 'JAVA-QUICK LP',
    lpAddresses: {
      80001: '',
      137: '0xBCD628917fc439B0F45513EB072E4bf22f4d2E75',
    },
    token: serializedTokens.quick,
    quoteToken: serializedTokens.java,
  },
  {
    pid: 10,
    lpSymbol: 'JAVA-SUSHI LP',
    lpAddresses: {
      80001: '',
      137: '0x477D35b4e568CE156E2955fcbD36439b4aC9F924',
    },
    token: serializedTokens.sushi,
    quoteToken: serializedTokens.java,
  },
  {
    pid: 11,
    lpSymbol: 'JAVA-FTM LP',
    lpAddresses: {
      80001: '',
      137: '0x1F7Df23299b8502A23ddaE43B79a8C8719EC05f4',
    },
    token: serializedTokens.ftm,
    quoteToken: serializedTokens.java,
  },
  {
    pid: 12,
    lpSymbol: 'JAVA-SHIB LP',
    lpAddresses: {
      80001: '',
      137: '0x5FC3Cc1a766F4f7662CF5DEf8F7ebEf8BCf5aDcB',
    },
    token: serializedTokens.shib,
    quoteToken: serializedTokens.java,
  },
]

export default farms
