import React from 'react'
import { Button, LogoIcon, Modal, Text, useModal } from '@javaswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { useWeb3React } from '@web3-react/core'
import { useJava, useJavaV1, useJavaV2 } from 'hooks/useContract'
import { migrateStep4 } from 'utils/calls'
import useToast from 'hooks/useToast'
import ConnectWalletButton from './ConnectWalletButton'
import AutomaticModal from './AutomaticModal'
import TransactionConfirmationModal from './TransactionConfirmationModal'

interface Props {
  onDismiss?: () => void
}

const ManualMigrationModal: React.FC<Props> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  const [onPresentAutomatic] = useModal(<AutomaticModal />)
  let error = false
  const [transactionModal] = useModal(
    <TransactionConfirmationModal
      title="Confirm all transactions"
      attemptingTxn
      content={null}
      hash=""
      pendingText=""
    />,
  )

  const JavaContract = useJava()
  const JavaV1Contract = useJavaV1()
  const JavaV2Contract = useJavaV2()

  const { toastError, toastSuccess } = useToast()

  const BeginSwap = async () => {
    transactionModal()
    error = await migrateStep4(account, JavaV1Contract, JavaContract, 'v1')
    if (!error) {
      error = await migrateStep4(account, JavaV2Contract, JavaContract, 'v2')
      if (!error) {
        toastSuccess('Satisfactory Migration', <Text>The migration was correctly, use yours Java V2</Text>)
      }
    }

    if (error) {
      toastError('Swap V1 👉 V2 Error', <Text>Try Again the swap v1 👉 v2</Text>)
    }
  }

  return (
    <Modal title={t('Migrate to Java V2 NOW!')} onDismiss={onDismiss}>
      {!account && <ConnectWalletButton mr="8px" />}
      {account && (
        <>
          <Button variant="gradient" endIcon={<LogoIcon />} onClick={onPresentAutomatic}>
            {t('Automate Migration')}
          </Button>
          <br />
          <Button color="primary" onClick={BeginSwap}>
            {t('Swap JAVA V1 👉 V2')}
          </Button>
        </>
      )}
    </Modal>
  )
}

export default ManualMigrationModal
