import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.java,
    earningToken: serializedTokens.java,
    contractAddress: {
      80001: '',
      137: '0xEdA633aE2D1CBf73E7df9E273b7680330061Ad0A',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.002',
    sortOrder: 1,
    isFinished: false,
  },
]

export default pools
