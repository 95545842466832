export default {
  masterBrewV1: {
    137: '0xC0C59deDb7C982F051934E23B5DE2e3d52CD7798',
  },
  masterBrewV2: {
    137: '0x3983E285B68Ca99bB649dF2bcd4aaeBFF071bEE5',
  },
  masterBrew: {
    137: '0xEdA633aE2D1CBf73E7df9E273b7680330061Ad0A',
  },
  sousChef: {
    137: '',
  },
  lotteryV2: {
    137: '0xF2C3199F8D32A69B5447E812E6Ec8C6DB322052e',
  },
  multiCall: {
    137: '0x513000eDEDD7244490C632CC806F5DB08dc73FE3',
  },
  referral: {
    137: '0xccE249c933374A1E83610247D5f90Da6d6ae357D',
  },
  javaProfile: {
    137: '',
  },
  javaRabbits: {
    137: '',
  },
  bunnyFactory: {
    137: '',
  },
  claimRefund: {
    137: '',
  },
  pointCenterIfo: {
    137: '',
  },
  bunnySpecial: {
    137: '',
  },
  tradingCompetition: {
    137: '',
    80001: '',
  },
  easterNft: {
    137: '',
  },
  javaVaultV1: {
    137: '0x8E04E1aAC497984a0f0BCcDb328c4C6f1d808AF5',
  },
  javaVaultV2: {
    137: '0x236dB9CEC0624c24f2a4D9BbF36899CD77Ca4bfb',
  },
  javaVault: {
    137: '0xe77e70ef1816Fa474f96F2893A482a23CE4EF737',
  },
  predictions: {
    137: '',
  },
  chainlinkOracle: {
    137: '',
  },
  bunnySpecialJavaVault: {
    137: '',
  },
  bunnySpecialPrediction: {
    137: '',
  },
  bunnySpecialLottery: {
    137: '',
  },
  farmAuction: {
    137: '',
  },
  nftMarket: {
    137: '0x2F12544845e9De26C9Bc8a4405AF4eBeaF1F899d',
  }
}
