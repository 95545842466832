import React from 'react'
import { Step, Card, CardBody, Modal, Stepper, Button, Text, useModal } from '@javaswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { useWeb3React } from '@web3-react/core'
import { migrateStep1, migrateStep2, migrateStep3, migrateStep4 } from 'utils/calls'
import {
  useJava,
  useJavaV1,
  useJavaV2,
  useJavaVaultV1Contract,
  useJavaVaultV2Contract,
  useMasterbrewV1,
  useMasterbrewV2,
} from 'hooks/useContract'
import multicall from 'utils/multicall'
import BigNumber from 'bignumber.js'
import javaVaultABI from 'config/abi/javaVault.json'
import { mainnetTokens } from 'config/constants/tokens'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import useToast from 'hooks/useToast'
import TransactionConfirmationModal from './TransactionConfirmationModal'

interface Props {
  onDismiss?: () => void
}

const AutomaticModal: React.FC<Props> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const javaVaultV1Contract = useJavaVaultV1Contract()
  const javaVaultV2Contract = useJavaVaultV2Contract()
  const JavaContract = useJava()
  const JavaV1Contract = useJavaV1()
  const JavaV2Contract = useJavaV2()
  const MasterBrewV1Contract = useMasterbrewV1()
  const MasterBrewV2Contract = useMasterbrewV2()
  const [transactionModal] = useModal(
    <TransactionConfirmationModal
      title="Confirm all transactions"
      attemptingTxn
      content={null}
      hash=""
      pendingText=""
    />,
  )

  const { account, library } = useWeb3React()

  const { toastError, toastSuccess } = useToast()
  const calls = [
    {
      address: javaVaultV1Contract.address,
      name: 'userInfo',
      params: [account],
    },
    {
      address: javaVaultV2Contract.address,
      name: 'userInfo',
      params: [account],
    },
  ]

  // Remove Liquidy Area
  const deadline = useTransactionDeadline()

  const farmv1 = [
    {
      pid: 1,
      address: '0x09E604a75583eBa6c678D5CFf354b41efE28D511',
      token: mainnetTokens.usdc,
      quoteToken: mainnetTokens.javav1,
    },
    {
      pid: 2,
      address: '0x70EA737A313451BB71E2880dB2cb442dEe9A7F1E',
      token: mainnetTokens.wmatic,
      quoteToken: mainnetTokens.javav1,
    },
    {
      pid: 3,
      address: '0xEf45E5814cC503fD3691DCd9128F4200D4e46D02',
      token: mainnetTokens.wmatic,
      quoteToken: mainnetTokens.usdc,
    },
  ]

  const farmv2 = [
    {
      pid: 1,
      address: '0x44C36130ebF8a35D52ADb8Bf8c0d12619784dE9d',
      token: mainnetTokens.wmatic,
      quoteToken: mainnetTokens.javav2,
    },
    {
      pid: 2,
      address: '0xEf45E5814cC503fD3691DCd9128F4200D4e46D02',
      token: mainnetTokens.wmatic,
      quoteToken: mainnetTokens.usdc,
    },
    {
      pid: 3,
      address: '0x0433dD2b5A3c0073Ba5aBA07dD1fb3561813fCb6',
      token: mainnetTokens.usdc,
      quoteToken: mainnetTokens.javav2,
    },
  ]

  const BeginAutomatic = async () => {
    transactionModal()
    let error = await migrateStep1(MasterBrewV1Contract, farmv1, account)
    if (!error) {
      error = await migrateStep1(MasterBrewV2Contract, farmv2, account)
      if (!error) {
        const rawResultsVault = await multicall(javaVaultABI, calls)
        const amountV1 = new BigNumber(rawResultsVault[0].shares._hex)
        const amountV2 = new BigNumber(rawResultsVault[1].shares._hex)
        if (amountV1.isGreaterThan(0)) {
          error = await migrateStep2(javaVaultV1Contract)
        }
        if (amountV2.isGreaterThan(0)) {
          error = await migrateStep2(javaVaultV2Contract)
        }
        if (!error) {
          error = await migrateStep3(account, deadline, library, farmv1, JavaV1Contract.address)
          if (!error) {
            error = await migrateStep3(account, deadline, library, farmv2, JavaV2Contract.address)
            if (!error) {
              error = await migrateStep4(account, JavaV1Contract, JavaContract, 'v1')
              if (!error) {
                error = await migrateStep4(account, JavaV2Contract, JavaContract, 'v2')
                if (!error) {
                  toastSuccess('Satisfactory Migration', <Text>The migration was correctly, use yours Java V2</Text>)
                }
              }
            }
          }
        }
      }
    }
    if (error) {
      const toast = toastError
      toast('Migration Error', <Text>Try Again the automatic migration</Text>)
    }
  }

  return (
    <Modal title={t('Thats we are going to do for you:')} onDismiss={onDismiss}>
      <Stepper>
        <Step index={0} status="past">
          <Card>
            <CardBody>
              <h2>{t('Step 1')}</h2>
              <div>{t('We will check if you have staked on Espresso Pools V1 or Java Farms V1.')}</div>
            </CardBody>
          </Card>
        </Step>
        <Step index={1} status="past">
          <Card>
            <CardBody>
              <h2>{t('Step 2')}</h2>
              <div>
                {t('Our program will then unstake your JAVA and liquidity from Espresso Pools V1 and Java Farms V1. ')}
              </div>
            </CardBody>
          </Card>
        </Step>
        <Step index={2} status="past">
          <Card>
            <CardBody>
              <h2>{t('Step 3')}</h2>
              <div>
                <strong>{t('For the Espresso Pools:')}</strong>{' '}
                {t('we will change the JAVA V1 to JAVA V2 at a 1:1 ratio')} <br />
                <strong>{t('For the Java Farms:')}</strong>{' '}
                {t('we will withdraw the JAVA-LP from the 2 tokens deposited;')} <br />
                {t('and then convert the JAVA V1 to JAVA V2 at a 1:1 ratio.')}
                <br />
              </div>
            </CardBody>
          </Card>
        </Step>
        <Step index={3} status="current">
          <Card>
            <CardBody>
              <h2>{t('Step 4')}</h2>
              <div>
                {t(
                  'Now you can create the new Liquidity Pools tokens and re-provide liquidity on Java Farms V2 here. ',
                )}{' '}
                <br />
                <strong>{t('Note:')} </strong> {t('For each transaction you will need aprove on metamask wallet.')}
              </div>
            </CardBody>
          </Card>
        </Step>
      </Stepper>
      <br />
      <br />
      <Button color="primary" onClick={BeginAutomatic}>
        Do it !
      </Button>
    </Modal>
  )
}

export default AutomaticModal
