import React from 'react'
import { Flex, Button, Text } from '@javaswap/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from 'contexts/Localization'
import { useGasNameManager } from 'state/user/hooks'
import useApiGasPrice from 'hooks/useApiGasPrice'

const GasSettings = () => {
  const { t } = useTranslation()
  const [gasName, setGasName] = useGasNameManager()
  const [apiGasPrice] = useApiGasPrice()

  return (
    <Flex flexDirection="column">
      <Flex mb="12px" alignItems="center">
        <Text>{t('Default Transaction Speed (GWEI)')}</Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="4px"
        />
      </Flex>
      <Flex flexWrap="wrap">
        <Button
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasName('Standard')
          }}
          variant={gasName === 'Standard' ? 'primary' : 'tertiary'}
        >
          {t('Standard (%gasPrice%)', { gasPrice: apiGasPrice ? apiGasPrice.safeLow : 'loading' })}
        </Button>
        <Button
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasName('Fast')
          }}
          variant={gasName === 'Fast' ? 'primary' : 'tertiary'}
        >
          {t('Fast (%gasPrice%)', { gasPrice: apiGasPrice ? apiGasPrice.standard : 'loading' })}
        </Button>
        <Button
          mr="4px"
          mt="4px"
          scale="sm"
          onClick={() => {
            setGasName('Instant')
          }}
          variant={gasName === 'Instant' ? 'primary' : 'tertiary'}
        >
          {t('Instant (%gasPrice%)', { gasPrice: apiGasPrice ? apiGasPrice.fast : 'loading' })}
        </Button>
      </Flex>
    </Flex>
  )
}

export default GasSettings
