import { FooterLinkType } from '@javaswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Contact'),
        href: 'https://javaswap.gitbook.io/javaswap/social-media-and-contact',
      },
      {
        label: t('Launch'),
        href: 'https://javaswap.gitbook.io/javaswap/untitled',
      },
      {
        label: t('Blog'),
        href: 'https://javaswapofficial.medium.com/',
      },
      {
        label: t('Community'),
        href: 'https://t.me/JavaSwapOfficialCommunity',
      },
      {
        label: t('JAVA token'),
        href: 'https://javaswap.gitbook.io/javaswap/javanomics',
      },
      {
        label: '—',
      },
      {
        label: t('Lunar Launch'),
        href: 'https://lunarlaunch.javaswap.io/',
        isHighlighted: true,
      },
      {
        label: t('Swap Daily'),
        href: 'https://swapdly.javaswap.io/',
      },
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: t('Customer Support'),
        href: 'https://t.me/JavaSwap_Official',
      },
      {
        label: t('Troubleshooting'),
        href: 'https://t.me/JavaSwap_Official',
      },
      {
        label: t('Guides'),
        href: 'https://javaswap.gitbook.io/javaswap/getting-started',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/javaswapdevelopment',
      },
      {
        label: t('Documentation'),
        href: 'https://javaswap.gitbook.io/javaswap',
      },
      {
        label: t('Bug Bounty'),
        href: 'https://t.me/JavaSwapOfficialCommunity',
      },
      {
        label: t('RugDog'),
        href: 'https://rugdoc.io/project/javaswap-v2/',
      },
      {
        label: t('Careers'),
        href: 'https://javaswap.gitbook.io/javaswap/social-media-and-contact/hiring',
      },
    ],
  },
]
